<template>
    <div class="content-wrapper">
        <!--Хлебные крошки-->
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <!---->
        <DeleteConnectorConfirm v-model="deleteConectorConfirmIsOpen"
        :connectors="connectorsForDelete"
        ></DeleteConnectorConfirm>
        <div class="content">
            <!--Диалоговые окна-->

            <v-dialog v-model="isAddUserDialog" width="500">
                <AddUserDialog :projectId="project.id"> </AddUserDialog>
            </v-dialog>
            <RenameUserDialog></RenameUserDialog>
            <div
                class="overlay"
                :style="{
                    backgroundSize: 'cover',
                    backgroundImage:
                        projectStatus === 'open' || projectStatus === 'beforeTrial'
                            ? `url(${require('@/assets/img/project/project-overlay.png')})`
                            : tariffNearToEnd
                            ? `url(${require('@/assets/img/project/project-overview-billing-beforEnd.svg')})`
                            : `url(${require('@/assets/img/project/project-overview-billing-dangeour.svg')})`,
                    backgroundColor:
                        projectStatus === 'open' || projectStatus === 'beforeTrial'
                            ? ''
                            : tariffNearToEnd
                            ? '#FF711C'
                            : 'red',
                }"
            ></div>
            <div>
                <div class="project-title-wrapper">
                    <h1 class="font-weight-medium project-title">{{ project.name }}</h1>
                    <RenameProjectBtn
                        :projectId="project.id"
                        v-if="projectStatus === 'open' && checkRole(project.id, 'project:rename')"
                    ></RenameProjectBtn>
                    <ProjectSettingsBtn
                        v-if="projectStatus === 'open' && checkRole(project.id, 'project:settings:view')"
                    ></ProjectSettingsBtn>
                    <HintIcon hintId="project" :white="true" class="ml-2"></HintIcon>
                </div>
                <Stories :style="{ marginTop: '15px' }" />
                <div class="mt-4">
                    <div class="btns-block ma-0">
                        <v-row align="center" class="ma-0">
                            <h2 class="font-weight-regular">Виджеты</h2>
                            <HintIcon hintId="widgets" class="ml-2"></HintIcon>
                            <v-spacer></v-spacer>
                            <ChangeViewBtn
                                v-model="dashBoardsView"
                                :cacheKey="'dashBoardsView'"
                            ></ChangeViewBtn>
                        </v-row>
                    </div>
                </div>
            </div>

            <DemoSubView
                v-if="projectStatus === 'lock' && dashBoardsView === 'cards'"
                :backroundLink="require('@/assets/img/project/expiredTariff/users-panel-cards.png')"
            ></DemoSubView>
            <CardsViewDashboards
                v-else-if="dashBoardsView === 'cards'"
                :dashBoards="dashBoards"
                :projectId="project.id"
                :projectStatus="projectStatus"
            ></CardsViewDashboards>

            <DemoSubView
                v-if="projectStatus === 'lock' && dashBoardsView === 'list'"
                :backroundLink="require('@/assets/img/project/expiredTariff/users-panel-list.png')"
            ></DemoSubView>
            <ListViewDashboards
                v-else-if="dashBoardsView === 'list'"
                :dashBoards="dashBoards"
                v-model="selectedConnectors"
            >
            </ListViewDashboards>

            <div class="mt-14">
                <div v-if="checkRole(project.id, 'project:users:view')">
                    <v-row align="center" class="ma-0">
                        <h2 class="font-weight-regular">Участники</h2>
                        <HintIcon hintId="users" class="ml-2"></HintIcon>
                    </v-row>
                    <div class="btns-block my-6" v-if="checkRole(project.id, 'project:users:edit')">
                        <AssignPermissionBtn :selectedUsers="selectedUsers" :projectId="project.id">
                        </AssignPermissionBtn>

                        <v-btn class="ml-2" outlined @click="deleteUsers" :disabled="usersBtnsDisable"
                            ><v-icon class="pr-1">mdi-close-circle</v-icon>Удалить</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            elevation="3"
                            color="main"
                            class="white--text btn-w-icon"
                            @click="openAddUserDialog"
                            v-if="checkRole(project.id, 'project:users:edit')"
                        >
                            <v-icon class="mr-5px"> mdi-plus </v-icon>Добавить участника</v-btn
                        >
                    </div>
                    <!--TODO: Вынести в компонент -->
                    <div
                        v-if="projectStatus === 'open' || projectStatus === 'beforeTrial'"
                        class="table-block mt-6"
                    >
                        <v-data-table
                            ref="userTable"
                            @input="selectUser($event)"
                            :headers="headers"
                            :value="selectedUsers"
                            :items="project.users"
                            :single-select="false"
                            @toggle-select-all="selectAllUsers"
                            :hide-default-footer="pagination"
                            item-key="id"
                            :show-select="
                                project.users.length > 1 && checkRole(project.id, 'project:users:edit')
                            "
                            class="black--text user-table"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <v-avatar v-if="!item.isPending" size="36" class="mr-3 my-1">
                                    <v-img
                                        transition="slide-x-transition"
                                        :src="item.avatar"
                                        :alt="item.email"
                                    />
                                </v-avatar>
                                <v-avatar v-else size="36" class="mr-3 my-1" color="#c7dfff">
                                    <v-icon>mdi-account</v-icon>
                                </v-avatar>
                                <span>{{ item.name }}</span>
                            </template>
                            <template v-slot:[`item.role`]="{ item }">
                                <span v-if="item.isPending">{{
                                    item.role.name + ' (Пользователь не зарегистрирован)'
                                }}</span>
                                <span v-else>{{ item.role.name }}</span>
                            </template>
                            <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                                <v-simple-checkbox
                                    :value="item.id == $auth.user.sub ? false : isSelected"
                                    v-if="
                                        item.id != $auth.user.sub &&
                                        checkRole(project.id, 'project:users:edit')
                                    "
                                    :disabled="item.id == $auth.user.sub"
                                    :readonly="item.id == $auth.user.sub"
                                    :ripple="false"
                                    @input="select($event)"
                                ></v-simple-checkbox>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :close-on-content-click="false" :nudge-width="200" offset-x offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            v-if="checkRole(project.id, 'project:users:edit')"
                                            :disabled="!checkRole(project.id, 'project:users:edit')"
                                            ><v-icon>mdi-dots-vertical</v-icon></v-btn
                                        >
                                    </template>

                                    <v-card min-width="198px">
                                        <v-list dense>
                                            <div
                                                v-if="
                                                    checkRole(project.id, 'project:users:edit') &&
                                                    item.id !== $auth.user.sub
                                                "
                                            >
                                                <v-list-item class="mx-3"
                                                    ><span class="black--text text-subtitle-2"
                                                        >ПРАВА</span
                                                    ></v-list-item
                                                >

                                                <v-list-item
                                                    v-for="role in roles"
                                                    :key="role.id"
                                                    class="text-btn-wrapper"
                                                    @click="reAssignPermission(item.id, role.id)"
                                                >
                                                    <span class="mx-3 my-2">{{ role.name }}</span>
                                                </v-list-item>

                                                <v-divider class="my-2"></v-divider>
                                            </div>
                                            <v-list-item
                                                class="text-btn-wrapper"
                                                v-if="checkRole(project.id, 'project:users:edit')"
                                                @click="changeUserName(item.id)"
                                            >
                                                <span class="mx-3 my-2">Изменить имя</span>
                                            </v-list-item>
                                            <v-list-item
                                                v-if="
                                                    checkRole(project.id, 'project:users:edit') &&
                                                    item.id !== $auth.user.sub
                                                "
                                                class="text-btn-wrapper"
                                                @click="deleteUser(item.id)"
                                            >
                                                <span class="mx-3 my-2">Удалить</span>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </div>
                    <DemoSubView
                        v-if="projectStatus === 'lock'"
                        :backroundLink="require('@/assets/img/project/expiredTariff/users-panel.png')"
                    ></DemoSubView>
                </div>
                <ScheduledReportSection
                    v-if="projectStatus === 'open' && checkRole(project.id, 'project:scheduledReport:view')"
                    class="mt-14"
                />
                <div class="connectors" v-if="checkRole(project.id, 'project:connector:view')">
                    <div class="mt-16">
                        <v-row align="center" class="ma-0">
                            <h2 class="font-weight-regular" id="connectors">Источники</h2>
                            <HintIcon hintId="connectors" class="ml-2"></HintIcon>
                        </v-row>
                    </div>
                    <div
                        class="btns-block mt-6 mb-4"
                        v-if="
                            checkRole(project.id, 'project:connector:delete') ||
                            checkRole(project.id, 'project:connector:edit')
                        "
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            elevation="3"
                            color="main"
                            class="white--text btn-w-icon"
                            @click="addConector"
                            v-if="checkRole(project.id, 'project:connector:edit')"
                            :disabled="!checkRole(project.id, 'project:connector:edit')"
                        >
                            <v-icon class="mr-5px"> mdi-plus </v-icon>Добавить источник</v-btn
                        >
                        <ChangeViewBtn
                            v-model="connectorsView"
                            :cacheKey="'connectorsView'"
                            class="ml-4"
                        ></ChangeViewBtn>
                    </div>

                    <div v-if="connectorsView === 'cards'" class="mt-4">
                        <template v-for="i in Math.ceil(project.connectors.length / 3)">
                            <v-row :key="i" class="ma-0"
                                ><ConnectorCard
                                    v-for="(connector, j) in project.connectors
                                        .filter((c) => !c.isChisai)
                                        .slice((i - 1) * 3, i * 3)"
                                    class="my-2"
                                    :class="j % 3 === 1 ? 'mx-4' : ''"
                                    :key="connector.connectorId"
                                    :connector="connector"
                                    :selectedConnectors="selectedConnectors"
                                    :loading="connector.loading"
                                ></ConnectorCard
                            ></v-row>
                        </template>
                    </div>
                    <ConnectorsListView
                        v-if="connectorsView === 'list'"
                        :connectors="project.connectors"
                        v-model="selectedConnectors"
                    ></ConnectorsListView>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useConfirmStore } from '../store/stores/confirm'

import Breadcrumbs from '@/components/Breadcrumbs'
import HintIcon from '@/components/HintIcon'
import AssignPermissionBtn from '@/components/buttons/AssignPermissionBtn'
import ChangeViewBtn from '@/components/buttons/ChangeViewBtn'
import CardsViewDashboards from '@/components/project/CardsViewDashboards'
import ConnectorCard from '@/components/project/ConnectorCard'
import ConnectorsListView from '@/components/project/ConnectorsListView'
import DashboardCard from '@/components/project/DashboardCard'
import ListViewDashboards from '@/components/project/ListViewDashboards'
import AddUserDialog from '@/components/project/dialogs/AddUserDialog'
import RenameUserDialog from '@/components/project/dialogs/RenameUserDialog'
import { checkAccess } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import cfg from '../../config'
import DemoSubView from './GCB2/subViews/DemoSubView.vue'
import OnBoarding from '../components/project/onBoarding/OnBoarding.vue'

import RenameProjectBtn from '@/components/buttons/RenameProjectBtn'
import ProjectSettingsBtn from '@/components/project/ProjectSettingsBtn.vue'
import { onMounted, ref } from 'vue'
import ScheduledReportSection from '../components/project/scheduledReport/ScheduledReportSection.vue'
import Stories from '../components/stories/Stories.vue'
import { checkRole } from '../helpers/roles'
import { useProject } from '../hooks/useProject'
import project from '../store/project'
import { useBillingStore } from '../store/stores/billing'
import { CONNECTOR_TYPES, GCB_EXTERNAL_CONNECTOR_TYPES } from '../vars/general'
import DeleteConnectorConfirm from '../components/project/dialogs/DeleteConnectorConfirm.vue'
export default {
    name: 'Project',
    components: {
        OnBoarding,
        DashboardCard,
        ConnectorCard,
        AddUserDialog,
        RenameUserDialog,
        Breadcrumbs,
        ChangeViewBtn,
        CardsViewDashboards,
        ListViewDashboards,
        ConnectorsListView,
        HintIcon,
        AssignPermissionBtn,
        RenameProjectBtn,
        ProjectSettingsBtn,
        ScheduledReportSection,
        Stories,
        DemoSubView,
        DeleteConnectorConfirm
    },
    setup(props, { emit }) {
        const { project } = useProject()
        const billingStore = useBillingStore()
        const loading = ref(true)
        onMounted(async () => {
            loading.value = true
            await Promise.all([
                billingStore.fetchCurrentTariff(project.value.id),
                billingStore.fetchTariffs(),
            ])

            loading.value = false
        })
        return {
            project,
            billingStore,
        }
    },
    data: () => ({
        dashBoardsView: 'cards',
        connectorsView: 'cards',
        loadingValue: null,
        loadingInterval: null,
        selectedConnectors: [],
        selectedUsers: [],
        deleteConectorConfirmIsOpen : false,
        connectorsForDelete : [],
        headers: [
            { text: 'Участник', value: 'name' /* width: '282px',  */ },
            { text: 'Права', value: 'role' /*  width: '594px' */ },
            { text: '', value: 'actions', /*  width: '78px', */ sortable: false, align: 'end' },
        ],
    }),

    computed: {
        ...mapState(['roles']),
        ...mapGetters(['projectById']),
        ...mapStores(useConfirmStore),
        project() {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        GCB2Connector() {
            return this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: '',
                    disabled: true,
                },
            ]
        },
        diffTariifDays() {
            const today = new Date()
            const activaUntil = new Date(this.currentTariff.activeUntil)
            return Math.floor((today - activaUntil) / (60 * 60 * 24 * 1000)) * -1
        },
        tariffNearToEnd() {
            if (!this.currentTariff) return false

            if (this.diffTariifDays < 4 && this.diffTariifDays > 0) return true
            else return false
        },
        projectStatus() {
            const [open, lock, beforeTrial] = ['open', 'lock', 'beforeTrial']
            if (!this.project.billingEnabled) return open

            if (this.project.billingEnabled && !this.isTrialActivatedBefore) {
                return beforeTrial
            }
            if (this.project.billingEnabled && (!this.currentTariff || this.diffTariifDays <= 0)) {
                return lock
            }
            return open
        },
        currentTariff() {
            const currentTariff = this.billingStore.getCurrentTariff(this.project.id)
            return currentTariff
        },
        isTrialActivatedBefore() {
            return this.billingStore.getIsTrialActivatedBefore()
        },
        dashBoards() {
            return [
                {
                    id: 'GCB2',
                    name: 'Кого из клиентов стоит позвать',
                    path: '/GCB2',
                    icon: {
                        color: '#BA7EC6',
                        name: '$clients',
                    },
                    show:
                        !this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.disabled && this.projectStatus === 'open',
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2Returnability',
                    name: 'Обзор удержания клиентов',
                    path: '/GCB2/returnability',
                    show: this.checkRole(this.project.id, 'returnability:view'),
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2MarketingReport',
                    name: 'Когортный анализ',
                    path: '/GCB2/marketingReport',
                    show: checkRole(this.project.id, 'marketingReport:view'),
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },

                {
                    id: 'GCB2Churn',
                    name: 'После каких сотрудников уходят клиенты',
                    path: '/GCB2/churn',
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    show:
                        !this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.disabled && checkRole(this.project.id, 'gcb2:churnReport:view'),
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2Staff',
                    name: 'Отчет по мастерам',
                    path: '/GCB2/staff',
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    show:
                        this.GCB2Connector &&
                        !this.GCB2Connector?.disabled &&
                        checkRole(this.project.id, 'gcb2:staffReport:view'),

                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2SameDayRec',
                    name: 'ОПЗ',
                    path: '/GCB2/sameDayRec',
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    show:
                        !this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.disabled && checkRole(this.project.id, 'gcb2:sameDayRec:view'),
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2Fourhands',
                    name: 'Отчет для 4hands',
                    path: '/GCB2/fourhandsReport',
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    show:
                        !this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.disabled &&
                        this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.showFourhandsReport,
                    //requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2Persona',
                    name: 'Отчет Персона',
                    path: '/GCB2/personaReport',
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    show:
                        !this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.disabled &&
                        this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.showPersonaReport,
                },
                {
                    id: 'GCB2ActivationReport',
                    name: 'Отчет по активациям',
                    path: '/GCB2/adminReportActivation',
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    show: this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                        ?.showActivationReport,
                },
                {
                    id: 'GCB2SimpleReports',
                    name: 'Общие отчеты',
                    path: '/GCB2/simpleReports',
                    show: checkRole(this.project.id, 'simpleReports:view'),
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2doctorReport',
                    name: 'Доктор DH',
                    path: '/GCB2/doctorReport',
                    show:
                        !this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.disabled && checkRole(this.project.id, 'gcb2:doctorReport:view'),
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2AvgCheckReport',
                    name: 'Отчет по среднему чеку',
                    path: '/GCB2/avgCheckReport',
                    show:
                        !this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.disabled &&
                        this.project.connectors.find((con) => con.connectorType === CONNECTOR_TYPES.GCB2)
                            ?.showAvgCheckReport &&
                        checkRole(this.project.id, 'gcb2:avgCheckReport:view'),
                    icon: {
                        color: '#A5C94B',
                        name: '$analytics',
                    },
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'GCB2KpiDasboard',
                    name: 'KPI сотрудников',
                    path: '/GCB2/kpi',
                    show:
                        this.checkRole(this.project.id, 'gcb2:kpi:view') ||
                        this.checkRole(this.project.id, 'gcb2:kpi:edit'),
                    icon: {
                        color: '#5EA0E3',
                        name: '$money',
                    },
                    requiredConnectorTypes: GCB_EXTERNAL_CONNECTOR_TYPES,
                },
                {
                    id: 'rolesSettings',
                    name: 'Гибкая настройка прав доступа',
                    path: '/settings/roles',
                    show: checkRole(this.project.id, 'project:rolesSettings:view'),
                    icon: {
                        color: 'rgb(255 197 73)',
                        name: 'mdi-cog',
                    },
                },
                {
                    id: 'marketplace',
                    name: 'Маркетплейс Data Heroes',
                    type: 'marketplace',
                    path: '/marketplace',
                    show: false,
                    icon: {
                        color: '#238ee7',
                        name: '$marketplace',
                    },
                },
            ].filter((dashboard) => {
                const enabledInCfg = cfg.disabledDashboardsIds
                    ? !cfg.disabledDashboardsIds.includes(dashboard.id)
                    : true
                return enabledInCfg && dashboard.show
            })
        },
        myRole() {
            if (this.project.users.find((user) => user.id === this.$auth.user.sub)) {
                return this.project.users.find((user) => user.id === this.$auth.user.sub).role.id
            }
            return 'observer'
        },
        pagination() {
            if (this.project.users.length < 10) {
                return true
            } else {
                return false
            }
        },
        usersBtnsDisable() {
            if (this.selectedUsers.length > 0) {
                return false
            } else {
                return true
            }
        },
        isAddUserDialog: {
            get() {
                return this.$store.state.project.isAddUserDialog
            },
            set(value) {
                this.$store.commit('project/updateIsAddUserDialog', value)
            },
        },
        selectedUserIds() {
            return this.selectedUsers.map((user) => user.id)
        },
    },
    watch: {
        connectorsView() {
            this.selectedConnectors = []
        },
    },
    methods: {
        checkAccess,
        checkRole,
        goToProjects() {
            this.$router.push({ path: '/projects' })
        },
        openAddUserDialog() {
            this.$store.commit('project/updateIsAddUserDialog', true)
        },
        addConector() {
            const currentPath = this.$router.currentRoute.path
            sessionStorage.setItem('afterAddConnectorRedirectPath', currentPath)
            this.$router.push(`${currentPath}/addConnectorStage1`)
        },
        selectConnector(connector) {
            if (!this.checkRole(this.project.id, 'project:connector:delete')) {
                return
            }
            if (!this.selectedConnectors.includes(connector.connectorId)) {
                this.selectedConnectors.push(connector.connectorId)
            } else {
                const index = this.selectedConnectors.findIndex(
                    (connectorId) => connectorId === connector.connectorId
                )
                this.selectedConnectors.splice(index, 1)
            }
        },
        selectUser(e) {
            let selectedItems = [...e].filter((el) => el.id !== this.$auth.user.sub)
            this.selectedUsers = selectedItems
        },
        selectAllUsers(e) {
            const selected = e.items
            let selectedItems = [...selected].filter((el) => el.id !== this.$auth.user.sub)
            if (this.selectedUsers.length === this.project.users.length - 1) {
                this.selectedUsers = []
            } else {
                this.selectedUsers = selectedItems
            }
        },
        clearSelectedUsers() {
            this.$emit('clearSelectedUsersCheckBoxes')
            this.selectedUsers = []
        },
        test(a) {
            console.log(a)
        },
        async deleteConnector(connectorId) {
            this.connectorsForDelete = [connectorId]
            this.deleteConectorConfirmIsOpen = true
        },
        async deleteUsers() {
            for (const userId of this.selectedUserIds) {
                await this.$store.dispatch('project/unAssignPermission', {
                    projectId: this.project.id,
                    userId,
                })
                this.$store.commit('removeUser', {
                    projectId: this.project.id,
                    userId,
                })
                this.selectedUsers = []
            }
        },
        async reAssignPermission(userId, roleId) {
            await this.$store.dispatch('project/reAssignPermission', {
                projectId: this.project.id,
                userIdtoChange: userId,
                roleId,
            })
        },
        async deleteUser(userId) {
            await this.$store.dispatch('project/unAssignPermission', {
                projectId: this.project.id,
                userId,
            })
            this.$store.commit('removeUser', {
                projectId: this.project.id,
                userId,
            })
        },
        async changeUserName(userId) {
            this.$store.commit('project/setCurrentUserIdToRename', userId)
            this.$store.commit('project/setIsRenameUserDialog', true)
        },
        openConnectorSettings(connectorId) {
            this.$router.push(`${this.$router.currentRoute.path}/connectorSettings/${connectorId}`)
        },
    },
    async created() {
        if (this.project && !this.checkRole(this.project.id, 'project:connector:edit')) {
            this.connectorsView = 'cards'
        }
        this.$on('deleteConnector', this.deleteConnector)
        this.$on('openConnectorSettings', this.openConnectorSettings)
        this.$on('clearSelectedUsers', this.clearSelectedUsers)
    },
    mounted() {
        //обработчик hash-ссылок
        if (this.$router.currentRoute.hash) {
            this.$scrollTo(this.$router.currentRoute.hash, 100, { offset: 500 })
        }
    },
    destroyed() {},
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.connector-selected
    background-color: black
.right-th-margin
    text-align: center
.margin-right-block
    spadding-right: 10%
.btns-block
    max-width: 1016px
    display: flex
    justify-content: space-between
.navigation
    cursor: pointer
.gray-btn-outline
    border: 1px solid #0000001F
.project-display-btn
    width: 36px
    height: 36px
    border: 1px solid #0000001f

.project-display-btn-left
    border-radius: 4px 0 0 4px

.project-display-btn-right
    border-radius: 0 4px 4px 0

.project-display-btn:focus
    outline: 0

.project-display-btn:hover
    background-color: #fff39b
    border: 1px solid #ffe100

.project-display-btn-active
    background-color: #fff39b
    border: 1px solid #ffe100

.v-application p
    margin: 0 !important

.table-block
    max-width: 1016px
button:focus
    outline: 0

.overlay
    position: absolute
    left: 0
    width: 100vw
    height: 232px
    background-position: 70%
.project-title
    color: white
    position: relative
    z-index: 1
    font-size: 48px
    max-width: 900px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
.project-title-wrapper
    display: flex
    align-items: center
    margin-top: 16px
</style>
